<template>
  <div class="new-cases-btn-wrapper__wrapper">
    <div class="new-cases-btn-wrapper__btn-wrapper"><Btn></Btn></div>
    <div class="new-cases-btn-wrapper__timer-block-wrapper">
      <TimerBlock :myId="2"></TimerBlock>
    </div>
    <Call></Call>
  </div>
</template>

<script>
import TimerBlock from "../Timer/TimerBlock.vue";
import Btn from "./Btn/Btn.vue";
import Call from "./Call/Call.vue";

export default {
  components: { Btn, Call, TimerBlock },
  data: () => ({}),
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style></style>
