export default {
  namespaced: true,
  state: { isActive: false },
  mutations: {
    SET_ACTIVE(state, newActiveState) {
      state.isActive = newActiveState;
    },
  },
  getters: {},
  actions: {
    toggleActive({ commit }, newActiveState = null) {
      if (newActiveState === null) {
        commit("SET_ACTIVE", !this.state.isActive);
      } else {
        commit("SET_ACTIVE", newActiveState);
      }
    },
  },
};
