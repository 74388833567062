var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products"},[(_vm.isLoading)?_c('Spinner'):_c('div',{staticClass:"products-wrapper"},[(!_vm.products || _vm.products.length === 0)?_c('div',[_c('p',[_vm._v("На данный момент нет таких товаров")])]):_vm._e(),_vm._l((_vm.products),function(card){return _c('div',{key:card.id,staticClass:"product-block"},[_c('div',{staticClass:"product-header__second-carousel",on:{"click":function($event){return _vm.getProductDetails(card.id)}}},[_c('img',{class:{
            'max-width-230': card.category.id === 3,
            'big-img-resize':
              card.title === 'Cardinar Scorpion - Чёрные' ||
              card.title === 'Cardinar Rich',
          },attrs:{"src":card.photo,"alt":"Cardinar – чехлы и полики"}}),_c('router-link',{staticClass:"product-title__second-carousel",class:{
            'skyline-product': card.title.toLowerCase().includes('skyline'),
          },attrs:{"to":"/constructor"}},[_c('img',{attrs:{"src":_vm.customiezer,"alt":"Cardinar – чехлы и полики"}}),_c('a',{attrs:{"href":card.link}},[_vm._v("customiezer")])])],1),_c('div',{staticClass:"product-second-carousel"},[_c('div',{staticClass:"product-copy__second-carousel"},[_c('p',{staticClass:"product-name__second-carousel"},[_vm._v(_vm._s(card.title))]),_c('p',{staticClass:"product-price__second-carousel"},[_vm._v(" "+_vm._s(_vm.prettifySum(card.price))+" сум ")])])])])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }