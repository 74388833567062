export default {
  namespaced: true,
  state: { isOpen: false, index: null },
  mutations: {
    TOGGLE_OPEN(state, newOpenState) {
      state.isOpen = newOpenState;
    },
    SET_INDEX(state, newIndex) {
      state.index = newIndex;
    },
  },
  getters: {},
  actions: {
    toggleOpen({ commit }, newOpenState) {
      commit("TOGGLE_OPEN", newOpenState);
    },
    setIndex({ commit }, newIndex) {
      commit("SET_INDEX", newIndex);
    },
  },
};
