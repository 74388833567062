<template>
  <div class="new-cases-swiper-mob__wrapper">
    <swiper-container
      class="new-cases-swiper-mob__swiper-container"
      init="false"
    >
      <swiper-slide class="new-cases-swiper-mob__swiper-slide"
        ><img
          class="new-cases-swiper-mob__swiper-img"
          :src="firstImg"
          alt="Чехлы Cardinar"
      /></swiper-slide>
      <swiper-slide class="new-cases-swiper-mob__swiper-slide"
        ><img
          class="new-cases-swiper-mob__swiper-img"
          :src="secondImg"
          alt="Чехлы Cardinar"
      /></swiper-slide>
      <swiper-slide class="new-cases-swiper-mob__swiper-slide"
        ><img
          class="new-cases-swiper-mob__swiper-img"
          :src="thirdImg"
          alt="Чехлы Cardinar"
      /></swiper-slide>
      <swiper-slide class="new-cases-swiper-mob__swiper-slide"
        ><img
          class="new-cases-swiper-mob__swiper-img"
          :src="textureImg"
          alt="Чехлы Cardinar"
      /></swiper-slide>
    </swiper-container>
    <div
      class="new-cases-swiper-mob__swiper-pagination"
      slot="pagination"
    ></div>
  </div>
</template>

<script>
import { register } from "swiper/element/bundle";
register();

export default {
  components: {},
  data: () => ({
    swiperEl: null,

    firstImg: require("@/assets/img/NewCases/Artboard1.jpg"),
    secondImg: require("@/assets/img/NewCases/Artboard2.jpg"),
    thirdImg: require("@/assets/img/NewCases/Artboard3.jpg"),
    textureImg: require("@/assets/img/NewCases/Texture.jpg"),
  }),
  mounted() {
    this.swiperEl = document.querySelector(
      ".new-cases-swiper-mob__swiper-container"
    );

    const swiperParams = {
      slidesPerView: 1,
      spaceBetween: 20,
      loop: true,
      pagination: {
        el: ".new-cases-swiper-mob__swiper-pagination",
        type: "bullets",
      },
    };

    Object.assign(this.swiperEl, swiperParams);

    this.swiperEl.initialize();
  },
  methods: {
    nextSlide() {
      this.swiperEl.swiper.slideNext();
    },
    prevSlide() {
      this.swiperEl.swiper.slidePrev();
    },
  },
  computed: {},
};
</script>

<style></style>
