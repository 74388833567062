<template>
  <header class="header">
    <img :src="hero" alt="Cardinar – чехлы и полики" class="hero-img" />
    <div class="container">
      <div class="header-wrapper">
        <div class="header-left">
          <p class="header-title">CARDINAR</p>
          <h1 class="header-subtitle">
            {{ $t("cardinarDescription") }} <br />
          </h1>
          <p class="header-copy">Barcha GM mashinalari uchun</p>
          <router-link to="shop" class="header-btn">
            <div class="header-btn__text">
              <p>в магазин</p>
              <div class="header-icon">
                <i class="far fa-long-arrow-right arrow-right"></i>
              </div>
            </div>
          </router-link>
        </div>
        <div class="header-wrapper__rigth">
          <router-link to="shop" class="header-btn">
            <div class="header-btn__text">
              <p>в магазин</p>
              <div class="header-icon">
                <i class="far fa-long-arrow-right arrow-right"></i>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data: () => ({
    hero: require("@/assets/img/header/hero.png"),
  }),
};
</script>

<style></style>
