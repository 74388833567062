<template>
  <div class="modal-img__wrapper">
    <swiper-container
      class="modal-img__swiper-container new-cases-modal-img__swiper-container-second"
      init="false"
    >
      <swiper-slide class="modal-img__swiper-slide">
        <img class="modal-img__img" :src="secondImg" alt="Чехлы Cardinar" />
      </swiper-slide>
      <swiper-slide class="modal-img__swiper-slide">
        <img class="modal-img__img" :src="thirdImg" alt="Чехлы Cardinar" />
      </swiper-slide>
      <swiper-slide class="modal-img__swiper-slide">
        <img class="modal-img__img" :src="textureImg" alt="Чехлы Cardinar" />
      </swiper-slide>
          <swiper-slide class="modal-img__swiper-slide">
        <img class="modal-img__img" :src="firstImg" alt="Чехлы Cardinar" />
      </swiper-slide>
    </swiper-container>

    <svg
      @click="prevSlide"
      class="modal-img__prev-btn"
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
    >
      <circle cx="37.5" cy="37.5" r="37.5" fill="#D9D9D9" />
      <path
        d="M43.3496 52.3574L29 38.0078L43.008 23.9999"
        stroke="black"
        stroke-width="3"
      />
    </svg>
    <svg
      @click="nextSlide"
      class="modal-img__next-btn"
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
    >
      <circle
        cx="37.5"
        cy="37.5"
        r="37.5"
        transform="rotate(-180 37.5 37.5)"
        fill="#D9D9D9"
      />
      <path
        d="M31.6504 22.6426L46 36.9922L31.992 51.0001"
        stroke="black"
        stroke-width="3"
      />
    </svg>
    <svg
      @click="closeModal"
      class="modal-img__close-btn"
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
    >
      <circle
        cx="37.5"
        cy="37.5"
        r="37.5"
        transform="rotate(-180 37.5 37.5)"
        fill="#D9D9D9"
      />
      <path
        d="M20 54.5C21.2 53.7 44.1667 30.5 55.5 19M20 19L55.5 54.5"
        stroke="black"
        stroke-width="3"
      />
    </svg>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { register } from "swiper/element/bundle";
register();

export default {
  components: {},
  data: () => ({
    swiperEl: null,

    firstImg: require("@/assets/img/NewCases/Artboard1.jpg"),
    secondImg: require("@/assets/img/NewCases/Artboard2.jpg"),
    thirdImg: require("@/assets/img/NewCases/Artboard3.jpg"),
    textureImg: require("@/assets/img/NewCases/Texture.jpg"),
  }),
  mounted() {
    this.swiperEl = document.querySelector(
      ".new-cases-modal-img__swiper-container-second"
    );

    const swiperParams = {
      slidesPerView: 1,
      loop: true,
      centeredSlides: true,
    };

    Object.assign(this.swiperEl, swiperParams);

    this.swiperEl.initialize();
  },
  methods: {
    nextSlide() {
      this.swiperEl.swiper.slideNext();
    },
    prevSlide() {
      this.swiperEl.swiper.slidePrev();
    },
    ...mapActions("NewCasesSwiper", ["toggleOpen"]),
    closeModal() {
      this.toggleOpen(false);
    },
  },
  computed: {
    ...mapState("NewCasesSwiper", ["isOpen"]),
    ...mapState("NewCasesSwiper", ["index"]),
  },
};
</script>

<style></style>
