<template>
  <div class="new-cases-timer__wrapper">
    <div class="new-cases-timer__line"></div>
    <div class="new-cases-timer__timer-block">
      <TimerBlock :myId="1"></TimerBlock>
    </div>
  </div>
</template>

<script>
import TimerBlock from "./TimerBlock.vue";

export default {
  components: { TimerBlock },
  data: () => ({}),
  mounted() {},

  methods: {},
  computed: {},
};
</script>

<style></style>
