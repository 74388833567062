<template>
  <div class="new-cases-timer-block__timer-container">
    <div v-show="!timeOut" class="new-cases-timer-block__start-through">
      <div
        class="new-cases-timer-block__timer-text"
        v-html="$t('startprodaj')"
      ></div>
      <div id="timer" class="new-cases-timer-block__squer-container">
        <div class="new-cases-timer-block__squer">
          <span class="timer__span" :id="`days-${myId}`"></span>
          <p>{{ $t("day") }}</p>
        </div>
        <div class="new-cases-timer-block__squer">
          <span class="timer__span" :id="`hours-${myId}`"></span>
          <p>{{ $t("hours") }}</p>
        </div>
        <div class="new-cases-timer-block__squer">
          <span class="timer__span" :id="`minutes-${myId}`"></span>
          <p>{{ $t("min") }}</p>
        </div>
        <div class="new-cases-timer-block__squer">
          <span class="timer__span" :id="`seconds-${myId}`"></span>
          <p>{{ $t("sec") }}</p>
        </div>
      </div>
    </div>

    <div v-show="timeOut" class="new-cases-timer-block__time-out">
      {{ $t("timerText") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    myId: {
      type: Number,
    },
  },
  components: {},
  data: () => ({ timerInterval: null, timeOut: false }),
  mounted() {
    const targetDate = new Date("2024-08-01T14:00:00").getTime();

    const updateTimer = () => {
      const now = new Date().getTime();
      const timeRemaining = targetDate - now;

      // Проверка достижения даты
      if (timeRemaining <= 0) {
        this.timeOut = true;
        clearInterval(this.timerInterval);
      } else {
        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        document.getElementById(`days-${this.myId}`).innerText = String(
          days
        ).padStart(2, "0");
        document.getElementById(`hours-${this.myId}`).innerText = String(
          hours
        ).padStart(2, "0");
        document.getElementById(`minutes-${this.myId}`).innerText = String(
          minutes
        ).padStart(2, "0");
        document.getElementById(`seconds-${this.myId}`).innerText = String(
          seconds
        ).padStart(2, "0");
      }
    };

    updateTimer();
    this.timerInterval = setInterval(updateTimer, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
  methods: {},
  computed: {},
};
</script>

<style></style>
