<template>
  <div class="three-js__br">
    <div @click="toggleActive" class="three-js__btn">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="27"
        viewBox="0 0 28 27"
        fill="none"
      >
        <path
          d="M26.375 1.125L1.62626 25.8737"
          stroke="black"
          stroke-width="2"
        />
        <path
          d="M25.748 25.75L0.99931 1.00126"
          stroke="black"
          stroke-width="2"
        />
      </svg>
    </div>
    <canvas id="three-js__poster"></canvas>
  </div>
</template>

<script>
import * as THREE from "three";

import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { LightProbeGenerator } from "three/addons/lights/LightProbeGenerator.js";

import { mapState, mapActions } from "vuex";

export default {
  mounted() {
    let renderer, scene, camera, cubeCamera, cube;

    let lightProbe;

    let canvasElement, cubeRenderTarget, controls, genCubeUrls, urls;

    let geometry, material, adjustPerspectiveAndRotation, animationId;

    let targetFOV = 45; // Целевое значение FOV
    let minFOV = 15; // Минимальный FOV
    let maxFOV = 45; // Максимальный FOV
    let minDistance = 10; // Минимальное расстояние до цели
    let maxDistance = 40;

    let minRotateSpeed = -0.2; // Минимальная скорость вращения
    let maxRotateSpeed = -0.4; // Максимальная скорость вращения

    let distance, newFOV, newRotateSpeed;

    init();

    function init() {
      canvasElement = document.getElementById("three-js__poster");

      // renderer
      renderer = new THREE.WebGLRenderer({
        canvas: canvasElement,
        antialias: true,
      });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(canvasElement.clientWidth, canvasElement.clientHeight);

      // scene
      scene = new THREE.Scene();

      // camera
      camera = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        1,
        1000
      );

      camera.position.set(0, 0, 30);

      cubeRenderTarget = new THREE.WebGLCubeRenderTarget(256);

      cubeCamera = new THREE.CubeCamera(1, 1000, cubeRenderTarget);

      // controls
      controls = new OrbitControls(camera, renderer.domElement);
      controls.addEventListener("change", render);
      controls.enableDamping = true;
      controls.dampingFactor = 0.1;
      controls.enablePan = false;
      controls.enableZoom = true;
      controls.minDistance = 10;
      controls.maxDistance = 40;
      controls.zoomSpeed = 5;



      adjustPerspectiveAndRotation = () => {
        distance = camera.position.distanceTo(controls.target); // Расстояние до цели

        // Линейная интерполяция для FOV

        newFOV =
          minFOV +
          (maxFOV - minFOV) *
            ((distance - minDistance) / (maxDistance - minDistance));
        targetFOV = THREE.MathUtils.clamp(newFOV, minFOV, maxFOV); // Ограничиваем FOV

        // Линейная интерполяция для скорости вращения
        newRotateSpeed =
          minRotateSpeed +
          (maxRotateSpeed - minRotateSpeed) *
            ((distance - minDistance) / (maxDistance - minDistance));
        controls.rotateSpeed = THREE.MathUtils.clamp(
          newRotateSpeed,
          minRotateSpeed,
          maxRotateSpeed
        );
      };

      controls.addEventListener("change", adjustPerspectiveAndRotation); // Вызов функции при изменении камеры

      function animate() {
        animationId = requestAnimationFrame(animate);
        controls.update();
        camera.fov = THREE.MathUtils.lerp(camera.fov, targetFOV, 0.1); // Плавное изменение FOV
        camera.updateProjectionMatrix();
        renderer.render(scene, camera);
      }

      animate();

      // probe
      lightProbe = new THREE.LightProbe();
      scene.add(lightProbe);

      // envmap
      genCubeUrls = function (prefix, postfix) {
        return [
          prefix + "px" + postfix,
          prefix + "nx" + postfix,
          prefix + "py" + postfix,
          prefix + "ny" + postfix,
          prefix + "pz" + postfix,
          prefix + "nz" + postfix,
        ];
      };

      urls = genCubeUrls("threeJsScenImg/", ".jpg");

      new THREE.CubeTextureLoader().load(urls, function (cubeTexture) {
        // scene.background = cubeTexture;

        geometry = new THREE.SphereGeometry(100, 128, 128);

        material = new THREE.MeshBasicMaterial({
          envMap: cubeTexture,
          side: THREE.BackSide,
        });
        cube = new THREE.Mesh(geometry, material);

        scene.add(cube);
        controls.update();

        cubeCamera.update(renderer, scene);
        lightProbe.copy(
          LightProbeGenerator.fromCubeRenderTarget(renderer, cubeRenderTarget)
        );

        render();
      });

      // listener
      window.addEventListener("resize", onWindowResize);
    }

    function onWindowResize() {
      renderer.setSize(window.innerWidth, window.innerHeight);

      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      render();
    }

    function render() {
      renderer.render(scene, camera);
    }

    this.$once("hook:beforeDestroy", () => {
      scene.traverse((child) => {
        if (child.isMesh) {
          child.geometry.dispose();
          child.material.dispose();
        }
      });
      scene.clear();

      // scene.background.dispose();
      cubeRenderTarget.dispose();

      lightProbe.dispose();

      controls.dispose();

      window.removeEventListener("resize", onWindowResize);
      controls.removeEventListener("change", adjustPerspectiveAndRotation);
      cancelAnimationFrame(animationId);

      renderer.dispose();
      renderer.forceContextLoss();

      renderer = null;
      scene = null;
      camera = null;
      cubeCamera = null;
      lightProbe = null;
      canvasElement = null;
      cubeRenderTarget = null;
      controls = null;
      genCubeUrls = null;
      urls = null;
      targetFOV = null;
      minFOV = null;
      maxFOV = null;
      minDistance = null;
      maxDistance = null;
      minRotateSpeed = null;
      maxRotateSpeed = null;
      distance = null;
      newFOV = null;
      newRotateSpeed = null;
    });
  },
  computed: {
    ...mapState("ThreeJS", ["isActive"]),
  },
  methods: {
    ...mapActions("ThreeJS", ["toggleActive"]),
  },
};
</script>

<style></style>
