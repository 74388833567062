<template>
  <div class="new-cases-info__wrapper">
    <HeaderText></HeaderText>
    <Timer></Timer>
    <Price></Price>
    <BtnWrapper></BtnWrapper>
  </div>
</template>

<script>
import HeaderText from "./HeaderText/HeaderText.vue";
import Price from "./Price/Price.vue";
import Timer from "./Timer/Timer.vue";
import BtnWrapper from "./BtnWrapper/BtnWrapper.vue";

export default {
  components: { HeaderText, Timer, Price, BtnWrapper },
  data: () => ({}),
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style></style>
