<template>
  <transition name="fade">
    <div v-show="isActive" class="new-cases-form__wrapper">
      <div @click="setActive(false)" class="new-cases-form__close-area"></div>
      <div class="new-cases-form__inner">
        <div @click="setActive(false)" class="new-cases-form__close-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
          >
            <path
              d="M26 1.125L1.61767 26.0006"
              stroke="black"
              stroke-width="2"
            />
            <path
              d="M25.3828 25.875L1.00048 0.999373"
              stroke="black"
              stroke-width="2"
            />
          </svg>
        </div>
        <div class="new-cases-form__title">{{ $t("formTitle") }}</div>
        <form class="new-cases-form__form" @submit.prevent="submitHandler()">
          <div class="new-cases-form__input-container">
            <input
              required
              type="text"
              class="new-cases-form__input"
              :placeholder="$t('name')"
              autocomplete="off"
              v-model="userName"
            />
          </div>

          <div class="new-cases-form__input-container">
            <input
              required
              type="tel"
              class="new-cases-form__input"
              :placeholder="$t('phone')"
              autocomplete="off"
              v-model="phoneNumber"
            />
          </div>

          <div class="new-cases-form__input-container">
            <input
              type="text"
              class="new-cases-form__input"
              :placeholder="$t('tg')"
              autocomplete="off"
              v-model="userEmail"
            />
          </div>

          <button class="new-cases-form__btn" type="submit">отправить</button>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data: () => ({
    userName: "",
    phoneNumber: "",
    userEmail: "",
    userComments: "Заявка на BMW 7 с главной страницы",
  }),
  mounted() {},

  computed: {
    ...mapState("NewCasesForm", ["isActive"]),
  },
  methods: {
    ...mapActions("NewCasesForm", ["toggleActive"]),
    setActive(value) {
      this.toggleActive(value);
    },

    submitHandler() {
      const formData = {
        full_name: this.userName,
        phone_number: this.phoneNumber,
        email: "telegram: " + this.userEmail,
        comment: this.userComments,
      };
      const jsonFormData = JSON.stringify(formData);

      axios
        .post("/inquiries/inquiry/", jsonFormData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // Handle the response if needed
          // Reset form fields after successful form submission
          this.userName = "";
          this.phoneNumber = "";
          this.userEmail = "";

          Swal.fire({
            icon: "success",
            title: "Отправлено!",
            text: "Ваша Заявка успешно отправлена!",
            showConfirmButton: false,
            timer: 1500, // Display for 1.5 seconds
          });
        })
        .catch((error) => {
          // Handle error if needed

          Swal.fire({
            icon: "error",
            title: error.response.data.phone_number[0],
            showConfirmButton: false,
            timer: 3000, // Display for 3 seconds
          });
        });
    },
  },

  beforeDestroy() {
    this.setActive(false);
  },
};
</script>

<style></style>
