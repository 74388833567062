var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{ref:"swiper",staticClass:"second-carousel__container swiper container"},[_c('div',{staticClass:"switch_arrows"},[_c('h2',{staticClass:"switch-arrows__txt"},[_vm._v(_vm._s(_vm.$t("popularСases")))]),_vm._m(0)]),_c('div',{staticClass:"swiper-wrapper body__second-carousel"},_vm._l((_vm.hitProducts),function(card){return _c('div',{key:card.id,staticClass:"swiper-slide card__second-carousel",on:{"click":function($event){return _vm.getProductDetails(card.id)}}},[_c('div',{staticClass:"card-header__second-carousel"},[_c('img',{class:{
            'max-width-250': card.category.id === 3,
            'big-img-resize':
              card.title === 'Cardinar Scorpion - Чёрные' ||
              card.title === 'Cardinar Rich',
          },attrs:{"src":card.photo,"alt":"Cardinar – чехлы и полики"}}),_c('router-link',{staticClass:"card-title__second-carousel",class:{
            'skyline-product': card.title.toLowerCase().includes('skyline'),
          },attrs:{"to":"/constructor"}},[_c('img',{attrs:{"src":_vm.customiezer,"alt":"Cardinar – чехлы и полики"}}),_c('a',{attrs:{"href":card.link}},[_vm._v("customiezer")])])],1),_c('div',{staticClass:"card-body__second-carousel"},[_c('div',{staticClass:"card-copy__second-carousel"},[_c('p',{staticClass:"card-name__second-carousel"},[_vm._v(_vm._s(card.title))]),_c('p',{staticClass:"card-price__second-carousel"},[_vm._v(" "+_vm._s(_vm.prettifySum(card.price))+" ")])])])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel_btn_container"},[_c('div',{staticClass:"btn_previous switch_carousel_btn_style complete_absence_on_the_page swiper-button-prev"},[_c('i',{staticClass:"far fa-chevron-left"})]),_c('div',{staticClass:"btn_next switch_carousel_btn_style swiper-button-next"},[_c('i',{staticClass:"far fa-chevron-right"})])])
}]

export { render, staticRenderFns }