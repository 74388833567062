<template>
  <div class="home">
    <NewCases></NewCases>
    <!-- <Header></Header> -->
    <HomeText :text="$t('homeText1')" :topMargin="true"></HomeText>
    <Banners></Banners>
    <Popular></Popular>
    <HomeText :text="$t('homeText2')" :title="$t('homeTitle2')"></HomeText>
    <Premium></Premium>
    <New></New>
    <HomeText :text="$t('homeText3')" :title="$t('homeTitle3')"></HomeText>
    <Popular></Popular>
    <Sale></Sale>
    <Help></Help>
  </div>
</template>

<script>
import NewCases from "@/components/NewCases/NewCases.vue";
import Header from "@/components/Header.vue";
import Banners from "@/components/Banners.vue";
import Popular from "@/components/Popular.vue";
import Premium from "@/components/Premium.vue";
import New from "@/components/New.vue";
import Help from "@/components/Help.vue";
import Sale from "@/components/Sale.vue";
import HomeText from "@/components/HomeText.vue";

export default {
  components: {
    NewCases,
    Header,
    Banners,
    Popular,
    Premium,
    New,
    Help,
    Sale,
    HomeText,
  },
};
</script>

<style></style>
