<template>
  <div>
    <div v-if="index == 1">
      <ModalImgFirst></ModalImgFirst>
    </div>
    <div v-else-if="index == 2">
      <ModalImgSecond></ModalImgSecond>
    </div>
    <div v-else-if="index == 3">
      <ModalImgThird></ModalImgThird>
    </div>
    <div v-else-if="index == 4">
      <ModalImgTexture></ModalImgTexture>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import ModalImgFirst from "./ModalImgSwiper/ModalImgFirst.vue";
import ModalImgSecond from "./ModalImgSwiper/ModalImgSecond.vue";
import ModalImgThird from "./ModalImgSwiper/ModalImgThird.vue";
import ModalImgTexture from "./ModalImgSwiper/ModalImgTexture.vue";

export default {
  components: { ModalImgFirst, ModalImgSecond, ModalImgThird, ModalImgTexture },
  data: () => ({}),
  mounted() {},
  methods: {},
  computed: {
    ...mapState("NewCasesSwiper", ["index"]),
  },
};
</script>

<style></style>
