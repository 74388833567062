<template>
  <div
    class="home-text__wrapper"
    :class="{ 'home-text__top-margin': topMargin }"
  >
    <h2 v-if="title" class="home-text__title">{{ title }}</h2>
    <h3 class="home-text__text" v-html="text"></h3>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["text", "title", "topMargin"],
};
</script>
