<template>
  <div class="new-cases__wrapper">
    <Swiper></Swiper>
    <SwiperMob></SwiperMob>
    <Info></Info>
    <div class="new-cases__three-js" v-if="isActive"><ThreeJS></ThreeJS></div>
    <NewCasesForm></NewCasesForm>
    <div v-if="isOpen"><ModalImg></ModalImg></div>
  </div>
</template>

<script>
import Swiper from "./Swiper/Swiper.vue";
import SwiperMob from "./Swiper/SwiperMob.vue";

import Info from "./Info/Info.vue";
import ThreeJS from "./ThreeJS/ThreeJS.vue";
import NewCasesForm from "./NewCasesForm/NewCasesForm.vue";
import ModalImg from "./ModalImg/ModalImg.vue";

import { mapState, mapActions } from "vuex";

export default {
  components: { Swiper, Info, ThreeJS, SwiperMob, NewCasesForm, ModalImg },
  data: () => ({}),
  mounted() {},
  computed: {
    ...mapState("ThreeJS", ["isActive"]),
    ...mapState("NewCasesSwiper", ["isOpen"]),
  },
};
</script>

<style></style>
