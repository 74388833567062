
export default {
  namespaced: true,
  state: { isActive: false },
  mutations: {
    TOGGLE_ACTIVE(state) {
      state.isActive = !state.isActive;
    },
  },
  getters: {},
  actions: {
    toggleActive({ commit }) {
      commit("TOGGLE_ACTIVE");
    },
  },
};
