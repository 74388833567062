<template>
  <div class="new-cases-btn__text">{{ $t("priziv") }}</div>
</template>

<script>
export default {
  components: {},
  data: () => ({}),
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style></style>
