<template>
  <div class="spinner-overlay">
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {
  data: () => ({
    progress: 0, // Define the progress property with an initial value
    counterClockwise: false, // Define the counterClockwise property with an initial value
  }),
};
</script>

<style>
.spinner-overlay {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
}
.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #333;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
</style>
